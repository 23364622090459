<template>
  <v-dialog id="dialog" v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn small text v-on="on" class="secondary--text mt-2">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        Adjust Balance
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>
          Adjust user balance
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="balanceForm" v-on:submit.prevent="save()">
          <v-text-field required type="number" label="Amount" hint="Enter negative number to debit the balance"
                
            v-model="amount" />  
        </v-form>
        <p class="caption">
          
        </p>
      </v-card-text>

      <v-card-actions>
          <v-btn text small outlined v-on:click.native="resetForm()" class="secondary--text">Cancel</v-btn>
          <v-spacer> </v-spacer>
          <v-btn small v-on:click.native="save()" text class="primary white--text">
            Update
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
    
<script>

export default {
  data() {
    return {
      dialog: false,
      amount: 0      
    }
  },

  props: {
    user: String,
    projectId: String
  },

  computed: {
    loading() {
      return this.$store.state.endUserTransactions.loading
    },
    error() {
      return this.$store.state.endUserTransactions.error
    },    
  },

  methods: {
    async save() {
      await this.$store.dispatch('UpdateBalance', {
        projectId: this.projectId,
        user: this.user,
        amount: parseInt(this.amount)
      })
      if (this.error === null) {
        let q = {
          projectId: this.projectId,
          user: this.user
        }
        this.$store.dispatch('GetBalance', q)

        // Reload the list of transactions
        let txQuery = {
          projectId: this.projectId,          
          pageToken: '',
          user: this.user,        
        }

        await this.$store.dispatch('ListEndUserTransactions', txQuery)

        this.resetForm()
      }
    },

    resetForm() {
      // closing dialog
      this.dialog = false
      
      this.amount = 0
    }    
  }
}

</script>